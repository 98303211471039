.support_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    height: 99vh;
    overflow: auto;
    flex: 1;
}

.support_main_container::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.support_main_container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.support_main_container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Radius of the scrollbar thumb */
}

.support_main_container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}