.certification_enrollment_main_container {
    display: flex;
}

.certification_enrollment_logo_container {
    padding: 20px;
    display: flex;
    cursor: pointer;
}

.certification_enrollment_logo_container > img {
    width:2.8rem;
    height: 3.2rem;
}

.certification_enrollment_logo_container > h1 {
    font-size: 1.8rem;
    margin-top: 22px;
}

.mobile_stepper_logo_container {
    padding: 10px;
    margin: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 120;
    background-color: #fffcf3;
    width: 100%;
}

.mobile_stepper_logo_container > img {
    width: 30px;
}

.mobile_stepper_logo_container > h3 {
    margin: 15px 0px 0px 10px;
}