.webinar_filter_container {
    width: 90%;
    height: 100px;
    padding: 20px 40px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 22px;
    margin: 20px 0px;
}

.webinar_filter_container_data {
    display: flex;
    justify-content: space-between;
}

.eventCard_main_container {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    height: 400px;
    background-color: #fff;
    border-radius: 12px;
    margin: 10px 20px;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition-duration: 500ms;
}

.eventCard_main_container:hover {
    transform: scale(1.02);
}

.eventCard_image_container {
    min-height: 200px;
    display: flex;
}

.eventCard_image_container>img {
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-fit: cover;
}

.view_details_button_container>button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 12px !important;
    padding: 5px !important;
    width: 100px;
    text-transform: inherit !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
}

.webinarEventsDrawer_events_youtube {
    display: flex;
    align-items: center;
    justify-content: center;
}

.webinarEventsDrawer_events_youtube>div>iframe {
    width: 40vw !important;
    height: 300px !important;
}

.webinarevents_drawer_main_container {
    height: 100vh;
    overflow: auto;
    padding: 0px 10px;
}