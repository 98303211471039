.problemStatement_main_container {
    display: flex;
    flex: 1;
}

.getLeftProblemStatement_container_statement_selected {
    display: flex;
    padding: 10px;
    background-color: #ff31314d;
    border-radius: 12px;
    margin-bottom: 20px;
}

.getLeftProblemStatement_container_statement_selected > h1 {
    font-size: 1vw;
    font-family: sans-serif;
    font-weight: 500;
}
.getLeftProblemStatement_container_statement {
    display: flex;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.getLeftProblemStatement_container_statement > h1 {
    font-size: 1vw;
    font-family: sans-serif;
    font-weight: 500;
}

.getLeftProblemStatement_container {
    flex: 1;
    padding: 30px 20px;
    min-width: 15vw;
    margin-left: -100px;
    margin-top: 25px;
    overflow-y: auto;
    height: 70vh;
}

.getProblemSolutionImages {
    flex: 3;
}

.problemStatement_mainContent {
    margin: 0px 30px 0px 20px;
    width: 36vw;
}

.problemStatement_mainContent_question_container h4 {
    font-size: 1.1vw;
    font-weight: 500;
    font-family: sans-serif;
    line-height: 1.5vw;
}