.leftScreen_reusable_mainContainer {
    display: flex;
    flex-direction: column;
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    width: 15vw;
    height: 100vh;
    background-color: #222;
    color: #fff;
}

.leftScreen_reusable_logo_container {
    padding: 20px 30px;
}

.leftScreen_reusable_logo_container > img {
    width: 50px;
    cursor: pointer;
}

.leftScreen_reusable_eachTitleMainContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    padding-left: 20px;
    cursor: pointer;
}

.leftScreen_reusable_eachTitleContainer {
    display: flex;
    align-items: center;
}

.leftScreen_reusable_eachTitleContainer > div {
    margin-right: 10px;
}

.leftScreen_reusable_eachTitle_title_selected> h3 {
    font-size: 1.2vw;
    font-weight: 600;
    color: #fff;
    margin: 0px 0px 2px 0px;
}
.leftScreen_reusable_eachTitle_title_unselected> h3 {
    font-size: 1.2vw;
    font-weight: 500;
    color: #888;
    margin: 0px 0px 2px 0px;
}

.leftReusable_unselectedIcon {
    color: #888;
    font-size: 1.5vw;
}

.leftReusable_selectedIcon {
    font-size: 1.5vw;
}