.myFilterAndSlide {
    margin: auto;
}

.myFilterAndSlide_main_container {
    display: flex;
    align-items: center;
    width: 80vw;
    overflow-x: auto;
    padding-bottom: 20px;
  }

.myFilterAndSlide_main_container::-webkit-scrollbar { width: 0 !important } 

.myFilterAndSlide_certification_card {
    min-width: 20vw;
    max-height: 60vh;
    border-radius: 12px !important;
    padding: 10px;
    margin: 0px 20px;
}

.myFilterAndSlide_certification_card_image {
    width: 120px;
}

.inprogress_certification_card_selected {
    padding: 0px 10px;
}

.myFilterAndSlide_certification_card_selected > div {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.myFilterAndSlide_certification_card_selected > div > h4 {
    margin: 5px 5px;
    font-size: 14px;
    font-family: sans-serif;
}

.myFilterAndSlide_certification_card_selected > h5 {
    margin: 0px;
    color: #888;
}

.myFilterAndSlide_certification_card_uploadButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
}

.myFilterAndSlide_certification_card_uploadButtonContainer > button {
    background-color: #FF3131 !important;
    color: #fff !important;
    width: 160px !important;
    height: 40px;
    text-transform: inherit !important;
    margin-right: 20px;
    cursor: pointer;
    border: none;
    border-radius: 12px;
}