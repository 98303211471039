.getSingleMovingCard_image_container {
    display: flex;
    align-items: center;
}

.continuous_curved_line_image_container > img {
    width: 120px;
    margin: 0px 0px -15px 10px;
}

.continuous_comments {
    width: 310px;
    height: 110px;
    margin-bottom: -20px;
}

.continuous_comments > h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    font-weight: 800;
    color: #888;
}

.brands-list {
    overflow: hidden;
    padding: 50px;
    width: 80%;
    margin: auto;
    box-shadow: 0px 4px 10px rgba(250, 57, 73, 0.13), 0 1px 10px rgba(255, 75, 90, 0.075);
    border-radius: 52px;
}

.wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 25rem;
    justify-items: stretch;
    animation: scroll 15s linear infinite;
}

@keyframes scroll {
    to {
        translate: calc(-1 * 25rem);
    }
}

