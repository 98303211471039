.dashboard_certificates_upperContainer_main {
    flex: 1;
    height: 99vh;
    overflow: auto;
}

.dashboard_certificates_upperContainer_main::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.dashboard_certificates_upperContainer_main::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.dashboard_certificates_upperContainer_main::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Radius of the scrollbar thumb */
}

.dashboard_certificates_upperContainer_main::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

.dashboardCertificate_right_main_container {
    padding: 20px;
}

.dashboardCertificate_upper_image {
    width: 300px;
}

.dashboardCertificates_certification_card {
    min-width: 20vw;
    max-height: 60vh;
    border-radius: 12px !important;
    /* padding: 10px; */
    margin: 0px 20px;
}

.dashboardCertificates_certification_card_image {
    width: 150px;
    height: 80px;
    object-fit: cover;
    margin-top: 10px;
}

.dashboardCertificates_certification_card_title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}

.dashboardCertificates_certification_card_selected {
    padding: 0px 10px;
}

.dashboardCertificates_certification_card_selected > div {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.dashboardCertificates_certification_card_selected > div > h4 {
    margin: 5px 5px;
    font-size: 14px;
    font-family: sans-serif;
}

.dashboardCertificates_certification_card_selected > h5 {
    margin: 0px;
    color: #888;
}

.dashboardCertificates_certification_reviewing_card_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    width: 10vw;
    border-radius: 22px;
    background: #CB356B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #BD3F32, #CB356B);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #BD3F32, #CB356B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    padding: 10px;
}

.dashboardCertificates_certification_reviewing_card_button > h3 {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
}

.dashboardCertificates_certification_card_uploadButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
}

.dashboardCertificates_certification_card_uploadButtonContainer > button {
    background-color: #FF3131 !important;
    color: #fff !important;
    width: 160px !important;
    height: 40px;
    text-transform: inherit !important;
    margin-right: 20px;
    cursor: pointer;
    border: none;
    border-radius: 12px;
}

.inreview_dashboard_certificates_uploadButtonContainer > button {
    border: 1px solid #FF3131 !important;
    color: #222 !important;
    width: 80px !important;
    height: 30px;
    text-transform: inherit !important;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    margin: 10px 20px 20px 10px;
}
.inreview_dashboard_certificates_uploadButtonContainer > button:hover {
    background-color:#FF3131 !important;
    color: #fff !important;
    width: 80px !important;
    height: 30px;
    text-transform: inherit !important;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    margin: 10px 20px 20px 10px;
}