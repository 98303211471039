.dashboard_courses_upperContainer_main {
    flex: 1;
    height: 99vh;
    overflow: auto;
}

.dashboard_courses_upperContainer_main::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.dashboard_courses_upperContainer_main::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.dashboard_courses_upperContainer_main::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Radius of the scrollbar thumb */
}

.dashboard_courses_upperContainer_main::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

.dashboardCourses_right_main_container {
    padding: 20px;
}

.dashboardCourses_upper_image {
    width: 200px;
}

.dashboard_courses_card {
    min-width: 20vw;
    max-height: 60vh;
    border-radius: 12px !important;
    padding: 10px;
    margin: 0px 20px;
}

.dashboard_courses_card_image {
    width: 120px;
}

.dashboard_courses_card_title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}

.dashboard_courses_card_selected {
    padding: 0px 10px;
}

.dashboard_courses_card_selected > div {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.dashboard_courses_card_selected > div > h4 {
    margin: 5px 5px;
    font-size: 14px;
    font-family: sans-serif;
}

.dashboard_courses_card_selected > h5 {
    margin: 0px;
    color: #888;
}

.dashboard_courses_card_uploadButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
}

.dashboard_courses_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard_courses_button_container > button {
    background-color: #FF3131 !important;
    color: #fff !important;
    width: 160px !important;
    height: 40px;
    text-transform: inherit !important;
    margin-right: 20px;
    cursor: pointer;
    border: none;
    border-radius: 12px;
}

.dashboardCourses_card_main {
    padding: 10px;
    min-width: 20vw;
    border-radius: 22px !important;
    margin: 15px 15px;
    cursor: pointer;
}

.dashboardCourses_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.dashboardCourses_image_container > img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 12px;
}

.dashboard_details_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard_details_container > p {
    margin: 10px 0px 10px 0px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.dashboard_details_container > span {
    font-size: 12px;
    color: #888;
    text-align: center;
}

.dashboard_courses_below {
    display: flex;
    align-items: center;
}

.dashboard_courses_below > div {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.dashboard_courses_below > div > span {
    font-size: 13px;
    font-weight: 600;
    color: grey;
    margin-left: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.dashboard_price > h4 {
    color: #888;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}