.suggestedCourse {
    padding: 10px;
    border: 1px solid lightgrey;
    width: 220px;
    border-radius: 12px;
    margin: 15px 10px;
    cursor: pointer;
}

.suggestedCourse:hover {
    padding: 10px;
    background-color: #fa2c2c0e ;
    border: 1px solid transparent;
    width: 220px;
    border-radius: 12px;
    margin: 15px 10px;
    cursor: pointer;
}

.suggestedCourses_selected {
    background-color: #fa2c2c25 ;
    padding: 10px;
    border: 1px solid transparent;
    width: 220px;
    border-radius: 12px;
    margin: 15px 10px;
    cursor: pointer;
}

.suggestedCourse_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.suggestedCourse_image_container > img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 12px;
}

.suggested_details_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.suggested_details_container > p {
    margin: 10px 0px 10px 0px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.suggested_details_container > span {
    font-size: 12px;
    color: #888;
    text-align: center;
}

.suggested_courses_below {
    display: flex;
    align-items: center;
}

.suggested_courses_below > div {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.suggested_courses_below > div > span {
    font-size: 13px;
    font-weight: 600;
    color: grey;
    margin-left: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.suggested_price > h4 {
    color: #888;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}