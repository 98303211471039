.education_circle {
  margin-top: 30px;
  /* position: absolute; */
}

.top_container {
  display: flex;
  align-items: center;
  margin: 40px 60px 40px 10px;
}

.headline_container {
  margin-left: 30px;
  margin-bottom: 30px;
}

.education_circle>img {
  width: 80px;
}

.headline>h1 {
  font-size: 5rem;
  margin: 0px 0px 0px 0px;
  text-align: center;
}

.headline>p {
  font-size: 20px;
}

.headline_specialText {
  color: #FF3131;
}

.headline_buttonContainer {
  display: flex;
  align-items: center;
}

.headline_button>button {
  background: #FF3131 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 8px !important;
  width: 130px;
  text-transform: inherit !important;
  border-radius: 10px !important;
  font-weight: 700 !important;
  margin-right: 30px;
}

.headline_image_container>img {
  width: 650px;
}

.education_banner {
  background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.education_banner>div {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

.education_banner>div>h1 {
  font-size: 4rem;
  margin: 20px 0px 0px 0px;
  color: #fff;
}

.education_banner>div>span {
  font-size: 12px;
  margin: 20px 0px 10px 0px;
  color: #fff;
  text-align: center;
}

.cards_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards_title>h1 {
  font-size: 3rem;
  margin: 20px 0px 10px 0px;
  text-align: center;
}

.cards_subtitle>p {
  margin: 0px 50px 0px 50px;
  text-align: center;
  font-size: 18px;
}

.card_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_text_container>p {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.card_text_container>span {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  word-spacing: 3px;
}

.all_cards_container {
  display: flex;
  justify-content: space-around;
  margin: 40px 40px 20px 40px;
}

.about_company_title {
  display: flex;
  justify-content: center;
}

.about_company_title>h1 {
  font-size: 3rem;
  margin: 10px 0px 10px 0px;
  text-align: center;
}

.about_company_subtitle>p {
  font-size: 18px;
  margin: 10px 50px 40px 50px;
  text-align: center;
  line-height: 26px;
}

.video_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  position: relative;

}

.video_container>video {
  width: 80%;
  height: 600px;
  border-radius: 52px;
  cursor: pointer;
  object-fit: cover;
}

.video_play_button {
  position: absolute;
  top: 50%;
}

.video_pause_button {
  position: absolute;
  top: 50%;
  opacity: 1;
  animation: fade 1s linear forwards;
}

@keyframes fade {
  0%, 50% {
    opacity: 1
  }

  50%, 75% {
    opacity: 0.6
  }

  75%, 100% {
    opacity: 0
  }
}

.howtogetmore_container {
  margin: 80px 50px 10px 50px;

}

.howtogetmore_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.howtogetmore_title>h1 {
  font-size: 3rem;
  text-align: center;
  margin: 0px;
}

.howtogetmore_title>p {
  font-size: 20px;
  text-align: center;
  margin: 10px;
  line-height: 28px;
}

.left_image_card {
  display: flex;
  justify-content: center;
  margin: 20px 20px;
}

.left_image_container {
  max-width: 500px;
}

.left_image_container>img {
  width: 500px;
  border-radius: 22px;
}

.left_image_details {
  width: 700px;
  margin-left: 60px;
}

.left_image_button>button {
  background: #FF3131 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 8px !important;
  width: 130px;
  text-transform: inherit !important;
  border-radius: 10px !important;
  font-weight: 700 !important;
  margin-right: 30px;
  margin-top: 50px;
}

.our_programs_container {
  margin-top: 50px;
}

.our_programs_title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.our_programs_title>h1 {
  font-size: 4rem;
  text-align: center;
}

.our_programs_card {
  width: 350px;
  height: 430px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 22px;
  margin: 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our_programs_card_video {
  background-color: #222;
  height: 200px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  width: 100%;
}

.our_programs_card_video>img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.program_readmore_button>button {
  background: linear-gradient(to right, #FF4B2B, #FF416C) !important;
  color: #fff !important;
  text-transform: inherit !important;
  margin-bottom: -70px;
}

.our_programs_cards_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatourstudentssay_container {
  margin-top: 50px;
}

.whatourstudentssay_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.whatourstudentssay_title>h1 {
  font-size: 4rem;
  text-align: center;
}

.students_comments_background {
  margin: 10px 50px;
  height: 150px;
  background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C);
  border-radius: 22px;
}

.education_homePage_modal_main_container {
  padding: 10px;
}

.education_homePage_modal_live_title_container {
  display: flex;
  justify-content: space-between;
}

.education_homePage_modal_live_title_container>div {
  display: flex;
  align-items: center;
}

.education_homePage_modal_live_title_container>div>h3 {
  font-size: 16px;
  margin: 10px 10px;
  font-weight: 700;
}

.education_homePage_event_title>h1 {
  font-size: 16px;
  margin: 10px 0px;
  font-weight: 800;
  color: #FF3131;
  line-height: 25px;
}

.education_homepage_youtube_container>div>iframe {
  max-width: 100% !important;
  height: 35vh !important;
}

.event_popup_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.event_popup_button>button {
  background: #FF3131 !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 8px !important;
  width: 130px;
  text-transform: inherit !important;
  border-radius: 10px !important;
  font-weight: 700 !important;
  margin-top: 30px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
  .education_circle {
    display: none;
  }

  .education_banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top_container {
    display: flex;
    align-items: center;
    margin: 40px 5px;
  }

  .headline_container {
    margin: 10px 10px 40px 10px;
  }

  .headline>h1 {
    font-size: 4rem;

  }

  .headline>p {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
  }

  .cards_title>h1 {
    font-size: 3rem;
    margin: 20px 10px;
  }

  .cards_subtitle>p {
    font-size: 18px;
    margin: 0px 10px;
    line-height: 30px;
  }

  .all_cards_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 40px 20px 40px;
    align-items: center;
  }

  .all_cards_container>div {
    margin-bottom: 20px;
  }

  .about_company_subtitle>p {
    font-size: 16px;
    margin: 10px 20px 40px 20px;
    text-align: center;
    line-height: 26px;
  }

  .about_company_below_title {
    padding-top: 40px;
  }

  .about_company_below_title>h1 {
    font-size: 28px;
    text-align: center;
    margin: 0px;
  }

  .about_company_all_cards {
    width: 250px;
    margin-top: 40px;
    cursor: pointer;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #222 !important;
    color: #fff !important;
  }

  .about_company_all_cards>div>h3 {
    font-size: 14px;
  }

  .howtogetmore_container {
    margin: 80px 20px 10px 20px;
  }

  .howtogetmore_title>p {
    font-size: 18px;
    text-align: center;
    margin: 0px;
  }

  .left_image_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 20px;
  }

  /* .left_image_container {
      max-width: 500px;
     } */

  .left_image_container>img {
    width: 300px;
    border-radius: 22px;
  }

  .left_image_details {
    width: 100%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .left_image_details>div>h1 {
    text-align: center;
    margin: 10px;
  }

  .left_image_details>div>p {
    margin: 10px 0px 10px 0px;
  }

  .left_image_button>button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    margin-right: 30px;
    margin-top: 20px;
  }

  .about_company_title>h1 {
    font-size: 3rem;
    margin: 10px 20px 10px 20px;
    text-align: center;
  }

  .whatourstudentssay_title>h1 {
    font-size: 3rem;
    text-align: center;
  }

  .students_comments_background {
    margin: 10px 50px;
    height: 320px;
    background: #FF416C;
    background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
    background: linear-gradient(to right, #FF4B2B, #FF416C);
    border-radius: 22px;
  }

  .our_programs_cards_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .our_programs_card {
    width: 350px;
    height: 430px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 22px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}