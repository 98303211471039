.courses_mobile_upperBar_main_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
}

.courses_mobile_logo_container > img  {
    width: 28px;
    height: 32px;
    margin-right: 10px;
    margin-left: 10px;
}

.course_mobile_search_container {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    border-radius: 22px;
    background-color: #ff313118;
    margin: 0px 10px 0px 0px;
    width: 170px;
}

.course_mobile_search_container > input {
    outline: 0;
    border: none;
    padding: 8px;
    height: 20px;
    font-size: 14px;
    background-color: transparent;
    color: #222;
}

.courses_mobile_allExplore_container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    height: 100px;
}

.courses_mobile_allExplore_container::-webkit-scrollbar {
    width: 0px;
}

.courses_mobile_allExplore {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.courses_mobile_filters {
    margin: 0px 10px;
    width: 120px;
    height: 50px;
    border: 1px solid lightgray;
    border-radius: 25px;
    /* padding: 15px 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.courses_mobile_filters_selected {
    margin: 0px 10px;
    width: 120px;
    height: 50px;
    background-color: #ff313118;
    border: 1px solid transparent;
    border-radius: 25px;
    /* padding: 10px 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.courses_mobile_filters > h4 {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: 700;
}

.courses_mobile_mainCourses {
    display: flex;
    align-items: start;
    background-color: #fffcf3;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 5px 12px;
    padding: 10px;
    margin: 10px 10px 30px 10px;
    border-radius: 12px;
    /* width: 320px; */
}

.courses_mobile_mainCourses_imageContainer > img {
    width: 80px;
    height: 70px;
    object-fit: cover;
    border-radius: 12px;
}

.courses_mobile_mainCourses_details_container {
    margin-left: 10px;
    width: 100%;
}

.courses_mobile_mainCourses_details_container > h3 {
    margin: 10px 0px 10px 0px;
}

.courses_mobile_main_courses_button_container > button {
    background-color: #f73535dc !important;
    color: #222 !important;
    width: 40px !important;
    height: 40px;
    text-transform: inherit !important;
    margin-right: 20px;
    
}