video {
    width: 250px;
    height: 150px;
    outline: none;
    border: none;
    object-fit: cover;
    clip-path: inset(0px)
}

.loading_animation_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}