.educationTopbarContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(18px) saturate(116%);
    -webkit-backdrop-filter: blur(18px) saturate(116%);
    background-color: rgba(246, 253, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.125);
    z-index: 100;
}

/* .logo {
    position: relative;
    width: 15rem;
    height: 10rem;
} */

.logo > img {
    /* position: absolute; */
    width: 200px;
    padding-top: 15px;
    padding-left: 50px;
}

.top_options_container {
    display: flex;
    justify-content: space-around;
    width: 45%;
    margin-top: 10px;
}

.top_options_container > h2 {
    margin-left: 20px;
    font-size: 16px;
    cursor: pointer;
}

.button_container {
    display: flex;
    justify-content: space-around;
    width: 18rem;
    margin-top: 10px;
    margin-right: 50px;
}

.sign_in {
    color: #222 !important;
    font-size: 14px !important;
    padding: 5px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    border: 1px solid #FF3131 !important;
    margin-right: 30px !important;
}
.sign_up {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 5px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    /* font-family: 'Noto Sans Display', sans-serif; */
}

@media only screen and (max-width: 768px){
    .logo > img {
        /* position: absolute; */
        width: 190px;
        padding-top: 15px;
        padding-left: 20px;
    }

    .menuIcon {
        margin-top: 10px;
        margin-right: 20px;
    }
}