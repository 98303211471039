.upload_solution_getSelectedCard_main_container {
    margin: 0px 20px;
    display: flex;
}

.upload_solution_main_container {
    flex: 1;
    height: 99vh;
    overflow: auto
}

.upload_solution_getSelectedCard_previewSection {
    max-width: 50vw;
    max-height: 98vh;
    overflow: auto;
}

.upload_solution_getSelectedCard_previewSection::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

.upload_solution_getSelectedCard_previewSection::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.upload_solution_getSelectedCard_previewSection::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Radius of the scrollbar thumb */
}

.upload_solution_getSelectedCard_previewSection::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}
.upload_solution_main_container::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

.upload_solution_main_container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.upload_solution_main_container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Radius of the scrollbar thumb */
}

.upload_solution_main_container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

.upload_solution_main_container_title > p {
    font-size: 14px;
    line-height: 22px;
}

.youtube_player_container > div > iframe {
    width: 30vw !important;
    height: 35vh !important;
}

.upload_solution_sendReviewSection {
    display: flex;
    flex-direction: column;
    align-items: center;;
    position: fixed;
    width: 25vw;
    top: 50px;
    right: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
}

.upload_solution_sendReview_inputSection > input {
    outline: 0;
    border: none;
    border-radius: 8px;
    padding: 15px;
    height: 10px;
    font-size: 16px;
    background-color: rgba(185, 185, 185, 0.233);;
    width: 24vw;
    color: #222;
}

.upload_solution_sendReview_inputSection > input::placeholder {
    color: #a5a5a5;
    font-size: 12px;
}

.upload_solution_sendReview_inputSection_button_container {
    margin: 20px 0px;
}

.upload_solution_sendForReview {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 0.9vw !important;
    padding: 8px !important;
    width: 13vw;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;

}
.upload_solution_goToDashboard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.upload_solution_goToDashboard > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 0.9vw !important;
    padding: 8px !important;
    width: 13vw;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;

}
.upload_solution_sendForReview_disabled {
    background: grey !important;
    color: #fff !important;
    font-size: 0.9vw !important;
    padding: 8px !important;
    width: 13vw;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;

}

.upload_solution_sendReviewSection_info_container > h4 {
    font-size: 1vw;
    font-weight: 500;
    text-align: center;
    color: #888;
    line-height: 22px;
}

.upload_solution_sendReviewSection_important_points {
    display: flex;
    align-items: center;
    background-color: #888;
    width: 24vw;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    margin: 20px 0px;
}

.upload_solution_sendReviewSection_important_points > span {
    font-size: 12px;
}

.upload_solution_applyForCard_details {
    padding: 0px 10px;
}

.upload_solution_applyForCard_details > div {
    display: flex;
    align-items: center;
}

.upload_solution_applyForCard_details > div > h4 {
    margin: 0px 10px;
    font-family: sans-serif;
}
.upload_solution_applyForCard_details >  h5 {
    margin: 10px 0px;
    color: #888;
}