.certificate_education_circle {
    margin-top: 30px;
    /* position: absolute; */
}

.certificate_education_circle > img {
    width: 80px;
}

.certificate_top_container {
    display: flex;
    align-items: center;
    margin: 40px 60px 40px 10px;
}

.certificates_headline_image_container > img {
    width: 650px;
}

.certificates_headline_container {
    margin-left: 30px;
    margin-bottom: 30px;
}

.certificates_headline > h1 {
    font-size: 5rem;
    margin: 0px 0px 0px 0px;
    text-align: center;
}
.certificates_headline > p {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
}

.certificate_cursor {
    font-size: 5rem;
    background-image: linear-gradient(to right, rgb(245, 70, 70), #ff6f36); /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
.certificate_typewriter {
    font-size: 5rem;
    background-image: linear-gradient(to right, rgb(245, 70, 70), #ff6f36); /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.certificate_headline_buttonContainer {
    display: flex;
    align-items: center;
}

.certificate_headline_button > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 180px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
    margin-right: 30px;
}

.certificates_getIcon_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    margin: 0px 50px;
}

.certificates_getIcon_container > img {
width: 80px;
height: 80px;
object-fit: cover;
display: flex;
align-items: center;
justify-content: center;
}

.certificates_getIcon_container > h4 {
    text-align: center;
    font-size: 16px;
    margin: 0px;
}

.certificates_getBoxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 52px;
    padding: 30px;
    width: 70%;
    margin: auto;
    box-shadow: 0px 4px 10px rgba(250, 57, 73, 0.13), 0 1px 10px rgba(255, 75, 90, 0.075);
}

.levelCertificates_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-top: 60px;
}

.levelCertificates_main_container > div > h1 {
    font-size: 2.5rem;
    background-image: linear-gradient(to right, rgb(245, 70, 70), #ff6f36); /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.selected_level {
    background: #222 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 52px !important;
    font-weight: 500 !important;
    margin-right: 30px !important;
}

.button_unselected_container {
    border: 1px solid #222 !important;
    color: #222 !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 52px !important;
    font-weight: 500 !important;
    margin-right: 30px !important;
}

.getCertificationContainer_main {
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(250, 57, 73, 0.13), 0 1px 10px rgba(255, 75, 90, 0.075);
    border-radius: 22px;
    margin-top: 40px;
    padding: 30px 40px;
}

.certificates_image_container {
    background-color: #ff3d3d17;
    padding: 30px;
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    

}

.certificates_image_container > img {
    width: 300px;
    height: 250px;
    object-fit: cover;
}

.certificates_points_container {
    display: flex;
    flex-direction: column;
    
}

.certificates_points_details_main_container {
    margin-left: 40px;
    /* margin-top: 40px; */
}

.certificates_points_details_main_container > h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.certificates_points_enroll_button {
    margin-top: 20px;
}

.certificates_points_enroll_button > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
    margin-right: 30px !important;
}

.certificates_acknowledgements_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px 0px 10px 0px;
}

.certificates_acknowledgements_container > h1 {
    font-size: 2.5rem;
    background-image: linear-gradient(to right, rgb(245, 70, 70), #ff6f36); /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

