.main_admin_panel_container {
  background-color: #222;
  height: 100vh;
  color: #fff;
  padding: 20px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 10px;
  }
  
  .grid-item {
    padding: 20px;
  }
  