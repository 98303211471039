.certificate_mobile_moving_text {
    font-size: 3rem;
    color: #222;
    position: absolute;
    font-weight: 800;
    background-image: linear-gradient(to right, rgb(245, 70, 70), #fa5268a9); /* Change #FFD700 to your desired color */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.certificate_mobile_explorenow_button > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 20px !important;
    padding: 8px !important;
    width: 250px;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
    margin-top: 20px;
}

.certificateMobile_internship_card_main {
    margin: 20px 8px;
    position: relative;
    height: 700px;
    background-color: #fff;
    padding-top: 50px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border-radius: 12px;
}

.certificateMobile_internship_card_tag {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    padding: 10px;
    background-color: #FF3131;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 12px;
}

.certificateMobile_internship_card_tag > h3 {
    margin: 0px;
    color: #fff;
    font-size: 14px;
}

.certificateMobile_internship_card_image {
    background-color: #ff3d3d17;
    padding: 30px;
    margin-top: 100px;
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    margin: 0px auto 30px auto;
}

.certificateMobile_internship_card_enroll {
    position: absolute;
    margin-bottom: 20px;       
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.certificateMobile_internship_card_enroll > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 170px;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
    margin-top: 20px;
    align-self: flex-end !important;
}

.certificateMobile_acknowledgement_container {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    position: relative;
}

.certificateMobile_acknowledgement_traingle > img {
    width: 34px;
    position: absolute;
    left: 20px;
}

.certificateMobile_acknowledgement_main_body {
    width: 200px;
    height: 150px;
    background-color: #FF5757;
    padding: 5px 10px;
    margin-bottom: -5px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 12px;
    white-space: wrap;
}
.certificateMobile_acknowledgement_main_body > h3 {
    font-weight: 800;
    font-size: 15px;
    color: #fff
}

.certificateMobile_list_wrapper {
    overflow: hidden;
    padding: 20px 0;
    white-space: nowrap;
    position: relative;

}

.certificateMobile_wrapper_slide {
    display: inline-flex;
    animation: 55s slide infinite linear;
}

@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

