.image-opening-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* .big-image img {
    width: 100%;
    max-width: 15vw;
  } */
  
  .small-images {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .small-image {
    margin: 0 10px;
  }

  .small-image img {
    max-width: 150px;
  }
  
  .overlay {
    position: relative;
  }
  
  .overlay-content {
    position: absolute;
    bottom: 0px; 
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px;
    width: 150px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view_result_button {
    border: 1px solid #FF3131 !important;
    color: #222 !important;
    font-size: 16px !important;
    padding: 5px !important;
    width: 100px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 500 !important;

  }
  .view_result_button:hover {
    background-color: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 5px !important;
    width: 100px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 500 !important;

  }
  