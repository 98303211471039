.webinar_events_youtube {
    display: flex;
    align-items: center;
    justify-content: center;
}
.webinarDrawer_events_youtube {
    display: flex;
    align-items: center;
    justify-content: center;
}

.webinar_events_youtube > div > iframe {
    width: 250px !important;
    height: 250px !important;
}
.webinarDrawer_events_youtube > div > iframe {
    width: 90vw !important;
    height: 300px !important;
}

.webinar_learn_more {
    display: flex;
    align-items: center;
    justify-content: center;
}

.webinar_learn_more > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    margin-bottom: 20px;
}

.upcoming_webinar_event_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.upcoming_webinar_event_button > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 8px !important;
    width: 130px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
}

.webinarDrawer_scrollableContainer {
   height: 90vh;
    overflow-y: scroll;
}

.webinar_events_card_container {
    display: flex;
    overflow: auto;
}