.education_footer {
    background: #FF416C;
    background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C); 
    background: linear-gradient(to right, #FF4B2B, #FF416C); 
    margin-top: 190px;
    padding: 50px;
}

.education_footer_container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0px 50px;
}

.education_footer_title > h1 {
    font-size: 4rem;
    color: #fff;
}

.our_details {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.our_details_data {
    margin-left: 10px
}

.our_details_data > p {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.education_footer_certificate > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.education_footer_certificate > div > div {
    margin-right: 40px;
}

.education_footer_certificate > div > h1 {
    color: #fff;
}

.education_footer_certificate > div > div > h3 {
    color: #fff;
}
.education_footer_certificate > div > div > li {
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
}

.education_footer_business > div > h1 {
    color: #fff;
}
.education_footer_business > div  > p {
    color: #fff;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .education_footer {
        background: #FF416C;
        background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C); 
        background: linear-gradient(to right, #FF4B2B, #FF416C); 
        margin-top: 190px;
        padding: 0px;
    }
    .education_footer_container {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
    }
    .education_footer_title > h1 {
        font-size: 3rem;
        color: #fff;
    }
    .education_footer_certificate > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}