.myStepper_welcome_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vw;
    overflow-y: auto;
    margin: 0 5% 0 12%;
}

.myStepper_welcome_main_title {
    margin-bottom: 40px;
}

.myStepper_welcome_title > h1 {
    font-size: 2.2rem;
    font-weight: 700;

}

.myStepper_welcome_subtitle_container {
    width: 450px;
}

.myStepper_welcome_subtitle_container > p {
    font-size: 14px;
    color: #888;
    text-align: center;
    line-height: 22px;
}

.myStepper_welcome_nextSteps_container > div {
    width: 32vw;
    background-color: #222;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 20px;
}

.myStepper_welcome_nextSteps_container > h3 {
    color: #222;
}

.myStepper_welcome_nextButton_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.myStepper_welcome_nextButton_container > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 180px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
    margin-right: 30px;
}

.myStepper_welcome_reviews {
    max-width: 300px;
    max-height: 80px;
    padding: 20px 10px;
    background: rgba(16, 15, 15, 0.219);
    border-radius: 16px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(199, 197, 197, 0.068);
    margin: 20px 0px;
}

.myStepper_welcome_reviews:nth-child(odd) {
    margin-left: 80px;
}

/* Even cards */
.myStepper_welcome_reviews:nth-child(even) {
    left: 0;
}

.myStepper_welcome_reviews > div {
    display: flex;
    align-items: center;
}


.myStepper_welcome_reviews >  div > img {
    width: 100px;
}


.myStepper_welcome_reviews > div > div > h3 {
    color: #fff;
    font-size: 14px;
    margin: 0px 0px 0px 10px;
}
.myStepper_welcome_reviews > div > div > h4 {
    color: #fff;
    font-size: 12px;
    margin: 0px 0px 0px 10px;
    font-weight: 300;
}

.myStepper_welcome_reviews > div  > span {
    color: #fff;
    font-size: 12px;
    margin: 0px 0px 0px 10px;
    font-weight: 500;
}

.stepper_bottom_buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    width: 85%;
    padding: 10px;
}

.stepper_previous {
    border: 1px solid#FF3131 !important;
    color: #222 !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 120px;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    margin-top: 20px;
    align-self: flex-end !important;
}
.stepper_previous:hover {
    background:#FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 120px;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    margin-top: 20px;
    align-self: flex-end !important;
}
.stepper_next {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 120px;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
    margin-top: 20px;
    align-self: flex-end !important;
}

.myStepper_summary_title_container {
    display: flex;
    align-items: center;
}

.myStepper_summary_title_icon {
    padding: 10px;
    background-color: #FF3131;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.myStepper_summary_title {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 35vw;
    border-radius: 12px;
}

.myStepper_summary_title > div > p {
    margin: 5px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myStepper_summary_title > h2 {
    margin: 0px;
    font-size: 18px;
}

.getPaymentScreen_main_screen {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.myStepper_summary_problemStatement_main_container {
    display: flex;
}

.myStepper_summary_problemStatement_container {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-width: 40vw;
    border-radius: 12px;
}

.myStepper_summary_problemStatement_main_statement {
    margin-top: 20px;
}

.myStepper_summary_problemStatement_title > p  {
    margin: 5px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myStepper_summary_problemStatement_title > div {
    display: flex;
    align-items: center;
}
.myStepper_summary_problemStatement_title > div > h2  {
    margin: 0px 0px 0px 5px;
    font-size: 18px;
    font-family: sans-serif;
}

.myStepper_summary_problemStatement_main_statement > p {
    margin: 5px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myStepper_summary_problemStatement_main_statement > h4 {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.myStepper_summary_problemStatement_image_container {
    margin-top: 20px;
}

.myStepper_summary_problemStatement_image_container > p{
    margin: 5px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myStepper_summary_problemStatement_image_container > img {
    margin: 10px 10px 0px 0px;
}

.getPaymentScreenRight_main_container {
    backdrop-filter: blur(21px) saturate(139%);
    -webkit-backdrop-filter: blur(21px) saturate(139%);
    background-color: rgba(17, 25, 40, 0.88);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    color: #fff;
    padding: 20px;
    width: 20vw;
    max-height: 70vh;
}

.getPaymentScreenRight_subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.getPaymentScreenRight_subtotal > p {
    margin: 0px;
    font-size: 16px;
}
.getPaymentScreenRight_subtotal > h4 {
    font-family: sans-serif;
    font-size: 20px;
    margin: 0px;
}

.getPaymentsScreenRight_couponCode {
    margin-top: 30px;
}

.getPaymentsScreenRight_couponCode > p {
    margin: 10px 0px;
    font-size: 14px;

}
.getPaymentsScreenRight_couponCode > input {
    outline: 0;
    border: none;
    padding: 10px;
    height: 20px;
    font-size: 14px;
    color: #222;
    border-radius: 8px;
    width: 80%;
    font-family: sans-serif;
}

.getPaymentsScreenRight_enrollNow_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.getPaymentsScreenRight_enrollNow_button > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 20vw;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;

}

.ant-popover-inner {
    padding: 5px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.ant-popover-arrow {
    display: none !important;
}

.ant-steps-item-tail {
    background-color: #FF3131 !important;

}


@media only screen and (max-width: 1200px) {
    .myStepper_welcome_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 90vh;
        overflow-y: auto;
        margin: 0px !important;
    }
  
}

