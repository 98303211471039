.registration_image_main_container {
    display: flex;
    flex: 1;
}

.main_form_container {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.registration_image_section_container>img {
    width: 100%;
    min-width: 40vw;
    height: 450px;
    object-fit: contain;
}

.registration_main_container {
    display: flex;
    justify-content: space-between;
    /* padding: 10px 50px; */
}

.registration_container>img {
    width: 150px;
    padding: 20px 50px;
}

.registration_details>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.registration_details>div>label {
    font-size: 16px;
    font-weight: 700;
}

.registration_details>div>input {
    outline: 0;
    border: none;
    padding: 15px;
    height: 10px;
    font-size: 16px;
    color: #222;
}

.registration_details_name_container {
    display: flex;
    flex-direction: row !important;

}


.registration_details_name {
    margin-right: 50px !important;
    width: 200px;
}

.registration_details_name>label {
    font-size: 16px;
    font-weight: 700;
}

.registration_details_name>input {
    outline: 0;
    border: none;
    padding: 15px;
    height: 10px;
    font-size: 16px;
    color: #222;
    margin-top: 10px;
}

.registration_details_password {
    display: flex;
    flex-direction: column;
}

.registration_details_password>label {
    font-size: 16px;
    font-weight: 700;
}

.registration_details_password>div>input {
    width: 80%;
}

.registration_details_phone {
    display: flex;
    align-items: center;
    border: 1.5px solid #dadadae0;
    border-radius: 12px;
    margin-top: 10px;
    width: 450px;
    height: 40px;
}

.registration_details_phone:focus,
.registration_details_phone:active,
.registration_details_phone:hover {
    box-shadow: 0 0px 5px #fd43d5b7;
    transition: 0.3s ease;
}

.registration_details_phone>input {
    outline: 0;
    border: none;
    padding: 15px;
    height: 10px;
    font-size: 16px;
    background-color: transparent;
    width: 400px;
    color: #222;
}

.registration_details_name_internal_container {
    display: flex;
    align-items: center;
    border: 1.5px solid #dadadae0;
    border-radius: 12px;
    margin-top: 10px;
    height: 40px;
}

.registration_details_name_internal_container:focus,
.registration_details_name_internal_container:active,
.registration_details_name_internal_container:hover {
    box-shadow: 0 0px 5px #fd43d5b7;
    transition: 0.3s ease;
}

.registration_details_name_internal_container>input {
    outline: 0;
    border: none;
    padding: 15px;
    height: 10px;
    font-size: 16px;
    background-color: transparent;
    width: 160px;
    color: #222;
}

.registration_details_phone>input::-webkit-outer-spin-button,
.registration_details_phone>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.registration_details_phone>input[type=number] {
    -moz-appearance: textfield;
}

.registration_details_phone>div>span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    margin-left: 5px;
}

.password_container {
    display: flex;
}


.forgot_password {
    margin-top: 10px;
}

.forgot_password>span {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.login_button {
    background: #222 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 450px !important;
    text-transform: inherit !important;
    font-weight: 700 !important;
    margin-top: 15px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;

}

.disabled_color {
    background: grey !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 450px !important;
    text-transform: inherit !important;
    font-weight: 700 !important;
    margin-top: 15px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    ;
}

/* .image_section_container {
    width: 500px;

} */

.image_section_container>img {
    width: 650px;
    height: 400px;
    object-fit: cover;
}

.registration_upper_container {
    margin-right: 100px;
    width: 500px;
}

.phone_field {
    display: flex;
    flex-direction: row !important;
    align-items: center;
}

.phone_field_input_container {
    margin: 0px 20px;
}

.phone_field_input_container>label {
    font-size: 16px;
    font-weight: 700;

}

.phone_field_input_container>div {
    display: flex;
    align-items: center;
    border: 1.5px solid #dadadae0;
    border-radius: 12px;
    margin-top: 10px;
    height: 40px;
    width: 330px;
}

.phone_field_input_container>div>input {
    outline: 0;
    border: none;
    padding: 15px;
    height: 10px;
    font-size: 16px;
    background-color: transparent;
    width: 160px;
    color: #222;
}

.phone_field_input_container>div>input::-webkit-outer-spin-button,
.phone_field_input_container>div>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.phone_field_input_container>div>input[type=number] {
    -moz-appearance: textfield;
}

.phone_field_input_container>div:focus,
.phone_field_input_container>div:active,
.phone_field_input_container>div:hover {
    box-shadow: 0 0px 5px #fd43d5b7;
    transition: 0.3s ease;
}

@media only screen and (max-width: 700px) {
    .registration_main_container {
        padding: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90vh;
    }

    .registration_container>img {
        width: 150px;
    }

    .welcome_title {
        font-size: 22px;
    }

    .welcome_subtitle {
        font-size: 14px;
    }

    /* .image_main_container{
        display: none;
    } */
    .registration_details {
        width: 80vw;
    }

    .registration_details>div>input {
        outline: 0;
        border: none;
        padding: 10px;
        height: 20px;
        font-size: 16px;
        color: #222;
    }

    .registration_upper_container {
        margin-right: 0px;
    }

    .registration_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .registration_form_title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .registration_details_phone {
        width: 300px;
    }

    .registration_details_phone>input {
        outline: 0;
        border: none;
        padding: 10px;
        height: 30px;
        font-size: 16px;
        background-color: transparent;
        width: 280px;
        color: #222;
    }

    .registration_details_name_container {
        display: flex;
        width: 300px;
    }

    .registration_details_name {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: 20px !important;
        width: 300px;
    }

    .registration_upper_container {
        width: 82vw;
    }

    .registration_container>img {
        width: 150px;
        padding: 20px 20px;
    }

    .registration_details_name_internal_container>input {
        outline: 0;
        border: none;
        padding: 15px;
        height: 10px;
        font-size: 16px;
        background-color: transparent;
        width: 100%;
        color: #222;
    }

    .registration_details_password>div>input {
        width: 65%;
    }

    .main_form_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 100%;
    }

    .main_form_container_title>h1 {
        font-size: 24px;
        font-weight: 800;
    }

    .form_submit_container>button {
        width: 300px !important;
    }

    .phone_field_input_container>div {
        width: 210px;
    }

}