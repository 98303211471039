.authentication_main_container {
    display: flex;
    justify-content: space-between;
    /* padding: 30px; */
}

.authentication_container {
    padding: 20px;
}

.authentication_container>img {
    width: 200px;
}

.authentication_details>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.authentication_details_internal_container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    border: 1.5px solid #dadadae0;
    border-radius: 8px;
    padding: 5px 0px;

}

.authentication_details_internal_container:focus,
.authentication_details_internal_container:active,
.authentication_details_internal_container:hover {
    box-shadow: 0 0px 5px #fd43d5b7;
    transition: 0.3s ease;
}

.authentication_details_internal_container>input {
    outline: 0;
    border: none;
    padding: 15px;
    height: 10px;
    font-size: 18px;
    background-color: transparent;
    width: 100%;
    color: #222;

}

.authentication_details>div>label {
    font-size: 16px;
    font-weight: 700;
}

/* .authentication_details > div > input {
    outline: 0;
    border: none;
    padding: 10px;
    height: 30px;
    font-size: 16px;
    background-color: rgba(185, 185, 185, 0.233);
    color: #222;
} */

.authentication_details_password {
    display: flex;
    flex-direction: column;
}

.authentication_details_password>label {
    font-size: 16px;
    font-weight: 700;
}

.authentication_details_password>div>input {
    width: 68% !important;
}

.password_container {
    display: flex;
}


.forgot_password {
    margin-top: 10px;
}

.forgot_password>span {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.authentication_login_button {
    background: #222 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 100% !important;
    text-transform: inherit !important;
    font-weight: 700 !important;
    margin-right: 30px;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;

}

.authentication_disabled_color {
    background: grey !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 100% !important;
    text-transform: inherit !important;
    font-weight: 700 !important;
    margin-right: 30px;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    ;
}

/* .image_section_container {
    width: 500px;

} */

.image_section_container>img {
    width: 650px;
    height: 400px;
    object-fit: cover;
}

.authentication_upper_container {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authentication_form {
    max-width: 30vw;
    min-width: 400px;
}

.authentication_form_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome_title {
    margin: 10px 0px;
}

.welcome_subtitle {
    margin: 10px 0px;
}

.image_main_container {
    flex: 1;
    background-color: #222;
    height: 100vh;
}

.authentication_image_text {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.authentication_image_text>h1 {
    text-align: center;
    font-size: 24px;
    width: 400px;
    margin: 0px;
}

.authentication_image_text>h4 {
    text-align: center;
    font-size: 16px;
    width: 400px;
}


@media only screen and (max-width: 700px) {
    .authentication_main_container {
        padding: 0px;
        margin: 20px 20px 10px 20px;
    }

    .authentication_container>img {
        width: 150px;
    }

    .welcome_title {
        font-size: 22px;
    }

    .welcome_subtitle {
        font-size: 14px;
    }

    .authentication_details>div>input {
        outline: 0;
        border: none;
        padding: 10px;
        height: 20px;
        font-size: 16px;
        background-color: rgba(185, 185, 185, 0.233);
        color: #222;
    }

    .authentication_upper_container {
        margin: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .authentication_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 300px;


    }

    .authentication_form_title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


}