.image_main_container {
    flex: 1;
    background-color: #222;
    height: 100vh;
}

.authentication_container {
    padding: 20px;
}

.authentication_container>img {
    width: 200px;
}

.authentication_image_text {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.authentication_image_text>h1 {
    text-align: center;
    font-size: 36px;
    width: 450px;
    margin: 0px;
}

.authentication_image_text>h4 {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    width: 400px;
}

.login_screen_animation_container>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 60vw;
}

@media only screen and (max-width: 700px) {
    .authentication_container>img {
        width: 150px;
    }
}