.bottomDrawer_main_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #fffcf3;
}

.bottomDrawer_topBar_title {
    display: flex;
    align-items: center;
    margin: 0px 10px;
}

.bottomDrawer_topBar_title > h3 {
    color: #f73535dc;
    font-weight: 800;
    font-size: 18px;
    margin-right: 10px;
}

.bottomDrawer_close_icon {
    padding: 8px;
    width: 16px;
    height: 16px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    /* position: absolute;
    right: 10px;
    top: 10px; */
}

.bottomDrawer_videoPlayer {
    width: 100%;
    position: relative;
    margin-top: 10px;
}

.bottomDrawer_videoPlayer > video {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.bottomDrawer_bookNow {
    padding: 20px 10px;
    position: sticky;
    bottom: 0px;
    width: 94%;
    box-shadow: 0 -2px 4px rgba(0,0,0,.08), 0 -4px 12px rgba(0,0,0,.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottomDrawer_bookNow > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 10px 10px !important;
    width: 160px !important;
    text-transform: inherit !important;
    border-radius: 12px;
}

.bottomDrawer_requirements_list > li {
    margin-bottom: 10px;
}