.mobile_stepper_welcomeScreenPoints_container {
    width: 80vw;
    background-color: #222;
    border-radius: 12px;
    padding: 10px;
    margin: auto;
    margin-bottom: 20px;
}


.mobile_stepper_bottom_container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0px -4px 4px -2px rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0;
    width: 95%;
    z-index: 100;
    background-color: #fffcf3;
}

.mobile_stepper_next_button {
    align-self: flex-end !important;
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 20vw;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
}
.mobile_stepper_prev_button {
    align-self: flex-end !important;
    border: 1px solid #FF3131 !important;
    color: #222 !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 20vw;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
}

.mobile_stepper_main_accordian_container {
    margin-top: 140px;
    margin-bottom: 80px;
}

.myMobileStepper_summary_title_container {
    display: flex;
    align-items: center;
}

.myMobileStepper_summary_title_icon {
    padding: 10px;
    background-color: #FF3131;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.myMobileStepper_summary_title {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    border-radius: 12px;
}

.myMobileStepper_summary_title > div > div > p {
    margin: 10px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myMobileStepper_summary_title > div > h2 {
    margin: 0px;
    font-size: 18px;
}

.myMobileStepper_summary_problemStatement_main_container {
    display: flex;
    margin-bottom: 80px;
}

.myMobileStepper_summary_problemStatement_container {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 12px;
}

.myMobileStepper_summary_problemStatement_main_statement {
    margin-top: 20px;
}

.myMobileStepper_summary_problemStatement_title > div > p  {
    margin: 10px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myMobileStepper_summary_problemStatement_title > div > div {
    display: flex;
    align-items: center;
}
.myMobileStepper_summary_problemStatement_title > div > div > h2  {
    margin: 0px 0px 0px 5px;
    font-size: 18px;
    font-family: sans-serif;
}

.myMobileStepper_summary_problemStatement_main_statement > p {
    margin: 5px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myMobileStepper_summary_problemStatement_main_statement > h4 {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.myMobileStepper_summary_problemStatement_image_container {
    margin-top: 20px;
}

.myMobileStepper_summary_problemStatement_image_container > p{
    margin: 5px 0px;
    font-size: 12px;
    font-weight: 700;
    color: #888;
}
.myMobileStepper_summary_problemStatement_image_container > img {
    margin: 10px 10px 0px 0px;
}

.mobile_stepper_coupon {
    margin-top: 30px;
}

.mobile_stepper_coupon > input {
    outline: 0;
    border: none;
    padding: 10px;
    height: 30px;
    font-size: 16px;
    color: #222;
    background-color: #fc8b8b15;
    border-radius: 8px;
    width: 90%;
    font-family: sans-serif;
}

.mobile_stepper_enrollNowButton > button {
    background:#FF3131 !important;
    color: #fff !important;
    font-size: 18px !important;
    padding: 10px !important;
    width: 100%;
    text-transform: inherit !important;
    border-radius: 12px !important;
    font-weight: 500 !important;
    margin-top: 40px;
}