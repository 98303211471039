.courses_logo_container {
    display: flex;
    align-items: center;
    margin-top: -10px;
    cursor: pointer;
}

.courses_logo_image_container {
    margin-left: -20px;
    margin-right: 10px;
}

.course_image_container {
    position: relative;
}

.course_image_container > img {
    width: 250px;
    height: 150px;
    border-radius: 20px;
    object-fit: cover;
    cursor: pointer;
}

.course_image_container > h1 {
    position: absolute;
    top: 60px;
    color: #fff;
    font-size: 21px;
    text-align: center;
    cursor: pointer;
}

.courses_main_container {
    padding: 10px 40px;
    display: flex;
}

.getExploreContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.getExploreContainer_title {
    margin-top: 10px;
}

.getExploreContainer_list {
    margin-bottom: 10px;
}


.getExploreContainer_list_each_container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 180px;
    cursor: pointer;
    border-radius: 12px;
    padding: 0px 10px;
    justify-content: space-between;
}

.getExploreContainer_list_each_container_selected {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 180px;
    justify-content: space-between;
    background-color: #ff31314d;
    border-radius: 12px;
    padding: 0px 10px;
    cursor: pointer;
}

.getExploreContainer_list_each_container > p {
    font-size: 16px;
    font-weight: 600;
}
.getExploreContainer_list_each_container_selected > p {
    font-size: 16px;
    font-weight: 600;
}

.courses_middleSection_container {
    padding: 10px 40px;
}

.courses_searchSection_container {
    display: flex;
    align-items: center;
    width: 520px;
    padding: 5px 20px;
    border-radius: 22px;
    background-color: #ff313105;
}

.courses_search_input_container > input {
    outline: 0;
    border: none;
    padding: 8px;
    height: 20px;
    font-size: 14px;
    background-color: transparent;
    color: #222;
    width: 450px;
}

.getSelectedResult_main_container {
    height: 76vh;
    background-color: #fa2c2c05 ;
    padding: 30px 10px;
    margin-top: 20px;
    overflow-y: scroll;
    border-radius: 22px;
}

.getSelectedResult_main_container::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
}

.getSelectedResult_main_container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.getSelectedResult_main_container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Radius of the scrollbar thumb */
}

.getSelectedResult_main_container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

.middleSectionCardMainContainer {
    display: flex;
    align-items: center;
    width: 520px;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 10px 10px;
}
.middleSectionCardMainContainer:hover {
    display: flex;
    align-items: center;
    width: 520px;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 10px 10px;
    background-color: #fa2c2c0e ;
    border-radius: 22px;
}
.middleSectionCardMainContainer_selected {
    display: flex;
    align-items: center;
    width: 520px;
    justify-content: space-between;
    margin-bottom: 40px;
    background-color: #fa2c2c25 ;
    border-radius: 22px;
    padding: 10px 10px;
}

.middleSectionCardContainer {
    display: flex;
    cursor: pointer;
}

.middleSectionCardImageContainer > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 22px;
    margin-right: 10px;
}

.middleSectionCard_details_title > h2 {
    margin: 0px 0px 10px 0px;
    font-size: 20px;
    font-weight: 650;
}
.middleSectionCard_details_subtitle {
    width: 300px;
    margin-bottom: 15px;

}
.middleSectionCard_details_subtitle > p {
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #888;
    font-weight: 500;
    
}

.middleSectionCard_details_tags_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 380px;
}

.middleSectionCard_details_tags {
    display: flex;
}

.middleSectionCard_details_tags > div > span {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    color: #888;
    
}

.middleSectionCard_details_video_details {
    display: flex;
    margin-left: 10px;
    align-items: center;
}

.middleSectionCard_details_video_details > div {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.middleSectionCard_details_video_details > div > span {
    font-size: 13px;
    font-weight: 600;
    color: grey;
    margin-left: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.rightSectionLoginButtonContainer {
    display: flex;
}

.rightSection_sign_in {
    color: #222 !important;
    font-size: 13px !important;
    padding: 5px !important;
    width: 80px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    border: 1px solid #FF3131 !important;
    margin-right: 10px !important;
    font-weight: 600 !important;
}
.rightSection_sign_up {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 13px !important;
    padding: 5px !important;
    width: 80px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    font-weight: 600 !important;
    /* font-family: 'Noto Sans Display', sans-serif; */
}

.rightUpperSection_main_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.rightUpperSection_container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.rightUpperSection_container > div {
    margin-right: 20px;
    cursor: pointer;
}

.rightUpperSection_container > div > p {
    font-size: 14px;
    font-weight: 700;
}

.getPreviewVideoPlayer {
    width: 100%;
    position: relative;
}

.getPreviewVideoPlayer > video {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 12px;
}

.getPreviewVideoPlayer_play_button {
    position: absolute;
    top:40%;
    left: 40%;
  }
 
  .getPreviewVideoPlayer_pause_button {
    position: absolute;
    top:40%;
    left: 40%;
    opacity: 1;
    animation: fade 1s linear forwards;
  }
 
  @keyframes fade {
    0%,50% { opacity: 1 }
    50%, 75% { opacity: 0.6 }
    75%, 100% { opacity: 0 }
  }

.getVideoDetails_booking_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.getVideoDetails_money_container > div > h1 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.getVideoDetails_bookNow > button {
    background: #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 5px !important;
    width: 180px;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    font-weight: 600 !important;
}

.getVideoDetails_course_details_button_container {
    display: flex;
    justify-content: center;
}

.getVideoDetails_course_details_button_container > button {
    border: 1px solid #FF3131 !important;
    color: #FF3131 !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 80% !important;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    font-weight: 600 !important;
    margin-top: 20px !important;
}
.getVideoDetails_course_details_button_container > button:hover {
    background-color:  #FF3131 !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 8px !important;
    width: 80% !important;
    text-transform: inherit !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    font-weight: 600 !important;
    margin-top: 20px !important;
}

.course_details_modal {
    overflow-y: scroll;
}

.course_details_modal::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.course_details_modal::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
}

.course_details_modal::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Radius of the scrollbar thumb */
}

.course_details_modal::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}

@media only screen and (min-width: 1800px) {
    .getPreviewVideoPlayer > video {
        width: 100%;
        height: 400px; 
        object-fit: cover;
        border-radius: 12px;
    }
}


